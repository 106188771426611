import Header from "../components/header";
import React from 'react';
import './base.css'
import './assignments.css'
const Assignments = () => {

    return (
        <div className="assignments" id="assignments">
            <Header />
            <ol className="assignments-list" id="assignments-list">
                <li className="assignments-list-item" id="assignments-list-item-0">
                    <a href="/documents/Reflection 1.pdf" target="_blank">Reflection 1 - Click here to view</a>
                </li>
                <li className="assignments-list-item" id="assignments-list-item-1">
                    <a href="/documents/CODES Contract.pdf" target="_blank">CODES Contract - Click here to view</a>
                </li>
                <li className="assignments-list-item" id="assignments-list-item-2">
                    <a href="/documents/Reflection 2.pdf" target="_blank">Reflection 2 - Click here to view</a>
                </li>
            </ol>
        </div>
    )
}
export default Assignments;